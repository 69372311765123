
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 流量卡返现记录
 */
export default defineComponent({
  name: 'SimCardReward',
  components: { SearchTable },
  setup() {
    const { https } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'orderno',
              label: '支付单号',
            },
            {
              field: 'agentId',
              label: '代理',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            }
          ]
        },
        remote: {
          action: https?.market.pageSimCardReward
        }
      },
      cols: [
        {
          field: 'simProfitRecordno',
          label: '返现记录号'
        },
        {
          field: 'orderno',
          label: '支付单号'
        },
        {
          field: 'profit',
          label: '返现金额',
        },
        {
          field: 'agentId',
          label: '代理ID'
        },
        {
          field: 'agentPhone',
          label: '代理手机号'
        },
        {
          field: 'agentName',
          label: '代理姓名'
        },
        {
          field: 'createTime',
          label: '创建时间'
        }
      ]
    };

    return { tableOpts };
  }
});
